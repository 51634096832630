import * as React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Trans } from 'react-i18next';

import { Highlight } from '@components/ui/highlight/highlight';
import bitcoinImg from '@images/bitcoin.webp';
import donateImage from '@images/donate-crypto-to-ukraine.webp';

import './donation.scss';

const Donation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="donation container--fluid">
      <div className="donation__title">
        <h1>
          {t('pages.howToDonate.title')}
        </h1>
        <img
          className="donation__title-graphics"
          src={bitcoinImg}
          alt="donate bitcoin"
        />
      </div>
      <h2>
        <Trans
          i18nKey="pages.howToDonate.subtitle"
          components={{ highlight: <Highlight /> }}
        />
      </h2>
      <div className="donation__info">
        <img
          style={{ order: '1' }}
          src={donateImage}
          alt="donate crypto to Ukraine"
        />
        <p style={{ order: '0' }}>
          <Trans
            i18nKey="pages.howToDonate.text"
            components={{ a: <a target="_blank" /> }}
          />
        </p>
        <p className="donation__justify" style={{ order: '2' }}>
          <Trans
            i18nKey="pages.howToDonate.text2"
            components={{ a: <a target="_blank" /> }}
          />
        </p>
        <p className="donation__justify" style={{ order: '3' }}>
          <Trans
            i18nKey="pages.howToDonate.text3"
            components={{ a: <a target="_blank" /> }}
          />
        </p>
      </div>
    </div>
  );
};

export default Donation;
