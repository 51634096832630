import * as React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Helmet } from 'react-helmet';
import Layout from '@components/layout/layout';
import Donation from '@components/donation/donation';

const HowToDonateCryptoToUkraine: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <Layout>
      <Helmet>
        <meta name="keywords" content={t('pages.howToDonate.metaKeywords')} />
        <meta name="description" content={t('pages.howToDonate.metaDescription')} />
        <title>{t('pages.howToDonate.documentTitle')}</title>
      </Helmet>
      <div className="container--fluid">
        <Donation />
      </div>
    </Layout>
  );
};

export default HowToDonateCryptoToUkraine;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
